import React, { useState, useEffect } from 'react'
import textFragmentImg from '../../images/desktop/text-fragment.svg'
import surveyImg from '../../images/desktop/survey.svg'
import documentImg from '../../images/desktop/document.svg'
import arrowDown from '../../images/desktop/arrow-down-3-blake.svg'
import stopBlockImg from '../../images/desktop/stop-block.svg'
import meetingImg from '../../images/desktop/meeting.svg'
import recordingImg from '../../images/desktop/recording.svg'
import audioImg from '../../images/desktop/audio.svg'



const lectionIcon = (lection, currentLection, trackers, finishCourseMessageIsActive, course) => {
  if (lection.content.is_video) {
    return (course.image_url)
  } else if (lection.content.is_survey) {
    return (surveyImg)
  } else if (lection.content.file_type == 'application/pdf') {
    return (documentImg)
  } else if (lection.content.file_type && lection.content.file_type.includes('image/')) {
    return (documentImg)
  } else if (lection.content.is_recording) {
    return (recordingImg)
  } else if (lection.content.is_stop_block) {
    return (stopBlockImg)
  } else if (lection.content.is_meeting) {
    return (meetingImg)
  } else if (lection.content.is_audio) {
    return (audioImg)
  } else {
    return (textFragmentImg)
  }
}

const lectionLinkClass = (lection, currentLection, trackers, finishCourseMessageIsActive) => {
  let baseClass = "btn btn-round btn-sm mb-0 stretched-link position-static "
  let colorClass

  if (currentLection.id === lection.id && !finishCourseMessageIsActive) {
    colorClass = "btn-primary-soft"
  } else if (trackers.filter(tracker => tracker.completed_at !== null).findIndex(tracker => tracker.lection_id == lection.id) !== -1) {
    colorClass = "btn-success-soft"
  } else {
    colorClass = "btn-danger-soft"
  }

  return baseClass.concat(colorClass)
}

const lectionNumber = (lections, currentLection) => {
  const lectionIndex = lections.findIndex(lection => lection.id == currentLection.id)
  return lectionIndex === -1 ? '' : lectionIndex + 1
}

function lectionDuration(lection) {
  return lection.content.is_video || lection.content.is_recording ? lection.content.duration : lection.content.start_time
}

function findSurveyLection(lections, lection) {
  if (!lection.content.is_stop_block) return null
  if (lection.content.survey_lection_id == null) return null
  else return lections.find(l => l.id === lection.content.survey_lection_id)
}

const CourseSection = (props) => {
  const [open, setOpen] = useState(props.isOpened)
  const { section, course, locale, currentLection, loadLection, index,
    trackers, notes, showFinishCourseLink, showFinishCourseMessage,
    finishCourseMessageIsActive, deleteNote, setPlayerCurrentTime } = props

  useEffect(() => {
    if (props.isOpened) {
      setOpen(true)
    }
  }, [props.isOpened]
  )

  const lectionNotes = (lection) => {
    return notes.filter(note => note.lection_id === lection.id)
  }

  const unfoldToggle = (e) => {
    e.target.closest('.block_title').classList.toggle('rotate')
    e.target.closest('.section').querySelector('.lessons_block').classList.toggle('hide')
  };

  return (
    <div className="section d-flex flex-column">
      {/* <h2 className="accordion-header" style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
        <button className={open ? 'accordion-button' : 'accordion-button collapsed'} type="button" aria-expanded={open}>
          <span className="mb-0 fw-bold">{window.t('course_viewer.section')} {index + 1}: {section.title}</span>
        </button>
      </h2> */}
      <div className="block_title d-flex" onClick={unfoldToggle}>
        {section.title}
        <img src={arrowDown} />
      </div>
      {
        <div className="lessons_block d-flex flex-column">
          {section.lections.map(lection => (
            <div key={'lection' + lection.id} className={`lesson d-flex flex-column ${currentLection.id == lection.id ? 'active_lesson' : ''} ${lection.visible ? '' : 'hide-block'}`}>
              <div className={`d-flex ${lection.viewable == true ? '' : 'viewing-disabled'}`}>
                <div className="position-relative d-flex align-items-center">
                  <a href="#" onClick={event => loadLection(event, lection, 0, findSurveyLection(section.lections, lection))} className={lectionLinkClass(lection, currentLection, trackers, finishCourseMessageIsActive)}>
                    {/* {lectionIcon(lection, currentLection, trackers, finishCourseMessageIsActive)} */}
                    <img src={lectionIcon(lection, currentLection, trackers, finishCourseMessageIsActive, course)} className="lesson_main" alt="" />
                  </a>
                  {!lection.content.is_stop_block ?
                    <span className="d-inline-block text-truncate ms-2 mb-0 h6 fw-light w-100px w-sm-200px">
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between align-items-start">
                          {lection.content.is_meeting && lection.content.completed ?
                            <a href="#" className="title">{lection.title}({window.t('course.meeting_to_video_short')})</a>
                            :
                            <a href="#" className="title">{lection.title}</a>
                          }
                        </div>
                        <div className="duration d-flex">
                          {lection.content.is_video || (lection.content.is_meeting && !lection.content.completed) || lection.content.is_recording ?
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" >
                                <path d="M14.6667 7.99992C14.6667 11.6799 11.68 14.6666 8.00004 14.6666C4.32004 14.6666 1.33337 11.6799 1.33337 7.99992C1.33337 4.31992 4.32004 1.33325 8.00004 1.33325C11.68 1.33325 14.6667 4.31992 14.6667 7.99992Z" stroke="#061310" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.4733 10.1199L8.40663 8.88659C8.04663 8.67326 7.7533 8.15992 7.7533 7.73992V5.00659" stroke="#061310" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              {lectionDuration(lection)}
                            </>
                            :
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" >
                                <path d="M14.6667 7.99992C14.6667 11.6799 11.68 14.6666 8.00004 14.6666C4.32004 14.6666 1.33337 11.6799 1.33337 7.99992C1.33337 4.31992 4.32004 1.33325 8.00004 1.33325C11.68 1.33325 14.6667 4.31992 14.6667 7.99992Z" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.4733 10.1199L8.40663 8.88659C8.04663 8.67326 7.7533 8.15992 7.7533 7.73992V5.00659" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              {/* {lection.content.duration} мин */}
                            </>
                          }
                        </div>
                        {console.log('lection', lection.completed_for_license)}
                        <svg width="269" height="5" viewBox="0 0 268 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect y="0.5" width="268" height="5" rx="2.5" fill="#E0EAE8" />
                          <rect y="0.5" width={trackers.filter(tracker => tracker.completed_at !== null).findIndex(tracker => tracker.lection_id == lection.id) != -1 ? '268' : "0"} height="5" rx="2.5" fill={"#329A80"} />
                        </svg>
                      </div>
                      {lection.content.status == 'transcoding' ?
                        <span>({window.t('course.video_transcoding_short')})</span>
                        :
                        ''
                      }

                    </span>
                    :
                    <p className="text-danger">{lection.notification}</p>

                  }

                </div>
              </div>

              {lectionNotes(lection).length > 0 &&
                <>
                  <div className="block_title">
                    {/* <a className="btn btn-xs btn-warning" data-bs-toggle="collapse" href={'#notes-' + lection.id} role="button" aria-expanded="true" aria-controls={'notes-' + lection.id}> */}
                    {window.t('course_viewer.view_notes')}
                  </div>
                  {lectionNotes(lection).map(note => (
                    <div className="action d-flex justify-content-between align-items-center" key={note.id}>
                      <div className="action_left d-flex align-items-center">
                        <a href="#" onClick={event => loadLection(event, lection, note.time_seconds)}>
                          Перейти
                        </a>
                        <div className="action_text">{note.title}<span>({new Date(note.time_seconds * 1000).toISOString().slice(11, 19)})</span></div>
                      </div>
                      <a onClick={() => deleteNote(note)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                          <path d="M21 6.47998C17.67 6.14998 14.32 5.97998 10.98 5.97998C9 5.97998 7.02 6.07998 5.04 6.27998L3 6.47998" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8.5 5.47L8.72 4.16C8.88 3.21 9 2.5 10.69 2.5H13.31C15 2.5 15.13 3.25 15.28 4.17L15.5 5.47" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M18.85 9.63989L18.2 19.7099C18.09 21.2799 18 22.4999 15.21 22.4999H8.79002C6.00002 22.4999 5.91002 21.2799 5.80002 19.7099L5.15002 9.63989" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.33 17H13.66" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9.5 13H14.5" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </a>
                    </div>
                    // <div key={'note' + note.id} className="d-flex justify-content-between bg-light rounded-2 p-2 mb-2">
                    //   <div className="d-flex align-items-center">
                    //     {lection.content.is_video &&
                    //       <span className="badge bg-dark me-2">{note.time}</span>
                    //     }
                    //     <h6 className="d-inline-block text-truncate w-40px w-sm-150px mb-0 fw-light">{note.title}</h6>
                    //   </div>
                    //   <div className="d-flex">
                    //     {lection.content.is_video &&
                    //       <a onClick={event => loadLection(event, lection, note.time_seconds)} className="btn btn-sm btn-light btn-round me-2 mb-0"><i className="bi fa-fw bi-play-fill"></i></a>
                    //     }
                    //     <a onClick={() => deleteNote(note)} className="btn btn-sm btn-light btn-round mb-0"><i className="bi fa-fw bi-trash-fill"></i></a>
                    //   </div>
                    // </div>
                  ))}
                </>
              }
            </div>
          ))}

          {showFinishCourseLink &&
            <div className="d-flex justify-content-between align-items-center my-2">
              <div className="position-relative d-flex align-items-center">
                <a href="#" onClick={showFinishCourseMessage} className="btn btn-round btn-sm mb-0 stretched-link position-static btn-danger-soft" >
                  <i className="fas fa-award me-0"></i>
                </a>
                <span className="d-inline-block text-truncate ms-2 mb-0 h6 fw-light w-100px w-sm-200px">
                  {course.lections.length + 1}. {window.t('course_viewer.finish_course')}
                </span>
              </div>
            </div>
          }
        </div>
      }
    </div>

  )
}

export default CourseSection
