import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleable", "icon"]

  toggle() {
    const isHidden = this.toggleableTarget.style.display === "none" || !this.toggleableTarget.style.display;

    if (isHidden) {
      this.toggleableTarget.style.display = "block";
      if (this.hasIconTarget) {
        this.iconTarget.style.transform = "rotate(180deg)";
      }
    } else {
      this.toggleableTarget.style.display = "none";
      if (this.hasIconTarget) {
        this.iconTarget.style.transform = "rotate(0deg)";
      }
    }
  }

  connect() {
    // Hide elements by default when controller loads
    this.toggleableTarget.style.display = "none";
    if (this.hasIconTarget) {
      this.iconTarget.style.transform = "rotate(0deg)";
    }
  }
}
