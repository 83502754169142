import React, { Component } from 'react'
import ActionCable from 'actioncable'
import { client } from './../Client'
import ConversationMessages from './ConversationMessages'
import ConversationMessageForm from './ConversationMessageForm'
import { toastrNotification } from '../../helpers/Toastr'

import noavatar from '../../images/noavatar.jpg'
import arrowLeft from '../../images/desktop/arrow-left.svg'
import more from '../../images/desktop/more.svg'
import closeCircle from '../../images/desktop/close-circle.svg'


class Conversation extends Component {

  state = {
    subscriptionActive: false,
    search: ""
  }

  subscription = {}
  channelName = "ConversationChannel"

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip()

    let _this = this;

    let listener = {
      received(data) {
        switch (data.action) {
          case 'create_message':
            _this.addNewMessage(data)
            break
          case 'delete_message':
            _this.deleteMessage(data)
            break
        }
        //console.log("received, channel:" + _this.channelName, data)
      }
    }

    ActionCable.debugging = true
    window.cable = ActionCable.createConsumer("/cable")
    this.subscription = window.cable.subscriptions.create({ channel: this.channelName, conversation_id: this.props.conversation.id }, listener)
    if (this.subscription) { this.setState({ subscriptionActive: true }) }
  }

  componentWillUnmount() {
    if (this.subscription) {
      setTimeout(() => { console.log('unsubscribed'); this.subscription.unsubscribe() }, 100);
    }
  }

  addNewMessage(data) {
    if (data.success == true) {
      const messageData = JSON.parse(data.message)
      this.refs.messagesList.addMessage(messageData)
      if (this.refs.messageForm !== undefined && data.author_id === this.props.currentUserId) {
        this.refs.messageForm.reset()
      }
    } else {
      if (data.author_id === this.props.currentUserId) {
        toastrNotification({ type: 'success', message: data.errors })
      }
    }
  }

  deleteMessage(data) {
    this.refs.messagesList.deleteMessageFromList(data.message_id)
  }

  onSearchChange = (evt) => {
    this.setState({ search: evt.target.value })
  }

  showMembersList = (e) => {
    e.preventDefault()
    $('#showMemberList').toggleClass('active')
    $('body').toggleClass('show-sidebar-right')

    if ($('.chat-sidebar-right').length > 0) {
      new PerfectScrollbar('.chat-sidebar-right', {
        suppressScrollX: true
      })
    }
  }

  showMainMenu = (e) => {
    e.preventDefault()
    let chatMenu = document.querySelector('.room_actions')
    if (chatMenu) {
      chatMenu.style.top = e.clientY + 10 + 'px'
      chatMenu.classList.toggle('show')
    }
    let alreadyOpen = document.querySelector('.edit')
    if (alreadyOpen) alreadyOpen.classList.remove('edit')
    let closestMessage = e.target.closest('.message')
    if (closestMessage) closestMessage.classList.add('edit')
  }
  closeMainMenu = (e) => {
    e.preventDefault()
    if (document.querySelector('.room_actions')) document.querySelector('.room_actions').classList.remove('show')
  }

  clearChat = (e) => {
    console.log('clearChat')

    e.preventDefault()
    const href = e.target.getAttribute('href')
    const modalEl = document.getElementById('deleteConfirmationModal');

    if (modalEl && href) {
      const buttonEl = modalEl.querySelector('form.button_to');
      const confirmTextEl = modalEl.querySelector('.confirm-text');
      const confirmTitleEl = modalEl.querySelector('.confirm-title');
      buttonEl.setAttribute('action', href);
      confirmTextEl.innerHTML = window.t('chat.conversation.delete_modal.course_group_conversation.warning');
      confirmTitleEl.innerHTML = window.t("chat.conversation.delete_modal.course_group_conversation.confirm");
      $('#close').on('click', function () {
        $('body').removeClass('shadow')
      })
      $('#cancel').on('click', function () {
        $('body').removeClass('shadow')
      })
      $('#delete-confirm').on('click', function () {
        $('body').removeClass('shadow')
      })

      // $('body').addClass('shadow')
      const modal = new bootstrap.Modal(modalEl, {});
      modal.show();
    }
    if (document.querySelector('.room_actions')) document.querySelector('.room_actions').classList.toggle('show')
  }

  showSearch = (e) => {
    e.preventDefault()
    document.querySelector('.search-chat').hidden = false
  }

  render() {

    const { conversation, canUpdateConversation, currentUserId } = this.props

    return (
      <>
        {/* <div className="block_bg fixed"> */}
        <div className="block_bg">
          <div className="block_top">
            <div className="container d-flex justify-content-between align-items-center">
              <div className="top_left d-flex align-items-center">
                {/* <a href="#" className="d-flex align-items-center left-arrow">
                  <img src={arrowLeft} alt="" />
                </a> */}
                <div className="chat_info d-flex">
                  {conversation.convo_mode == "group" && conversation.course_id ?
                    <img src={conversation.course_image_url} alt="" />
                    :
                    <img src={conversation.participants[1].avatar_url} alt="" />
                  }
                  <div className="d-flex flex-column">
                    <a href="#" className="chat_title" id="channelTitle">{conversation.name}</a>
                    {conversation.convo_mode == "group" && conversation.course_id &&
                      <span>{conversation.messages_size} сообщений</span>
                    }

                    {conversation.convo_mode == "direct" && conversation.participants[0].online == true &&
                      <span>В сети</span>
                    }
                    {conversation.convo_mode == "direct" && conversation.participants[0].online != true &&
                      <span className='offline'>Не в сети</span>
                    }
                  </div>
                </div>
                <a href="#" onClick={this.showMainMenu} className="chat_menu">
                  <img src={more} alt="" className="chat_menu" />
                </a>
              </div>
              <div className="search_wrap search-chat" hidden>
                <input type="search" onChange={this.onSearchChange} className="search" placeholder={window.t('search')} />
                {/* <button className="btn" type="button"><i data-feather="search"></i></button> */}
              </div>

            </div>
          </div>
        </div>
        {conversation.convo_mode == "direct" &&
          <ul className="room_actions">
            <li key="direct-search">
              <a href="" className="d-flex" onClick={this.showSearch}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M22 22L20 20" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Поиск
              </a>
            </li>
            <li key="direct-favorite" id="favoriteLink">
              <a href={Routes.toggle_favorite_conversation_path(conversation.id)} onClick={(e) => this.closeMainMenu(e)} className="d-flex" data-method="put" data-remote="true" data-toggle="tooltip" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={conversation.favorited_by.includes(currentUserId) ? "#ffc107" : "none"}>
                  <path d="M13.7299 3.50989L15.4899 7.02989C15.7299 7.51989 16.3699 7.98989 16.9099 8.07989L20.0999 8.60989C22.1399 8.94989 22.6199 10.4299 21.1499 11.8899L18.6699 14.3699C18.2499 14.7899 18.0199 15.5999 18.1499 16.1799L18.8599 19.2499C19.4199 21.6799 18.1299 22.6199 15.9799 21.3499L12.9899 19.5799C12.4499 19.2599 11.5599 19.2599 11.0099 19.5799L8.01991 21.3499C5.87991 22.6199 4.57991 21.6699 5.13991 19.2499L5.84991 16.1799C5.97991 15.5999 5.74991 14.7899 5.32991 14.3699L2.84991 11.8899C1.38991 10.4299 1.85991 8.94989 3.89991 8.60989L7.08991 8.07989C7.61991 7.98989 8.25991 7.51989 8.49991 7.02989L10.2599 3.50989C11.2199 1.59989 12.7799 1.59989 13.7299 3.50989Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {conversation.favorited_by.includes(currentUserId) ? window.t('chat.conversation.remove_from_favorites') : window.t('chat.conversation.add_to_favorites')}
              </a>
            </li>
            <li key="direct-delete">
              <a href={Routes.open_delete_modal_conversation_path(conversation.id)} data-modal="true" className="d-flex" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M18.8504 9.13989L18.2004 19.2099C18.0904 20.7799 18.0004 21.9999 15.2104 21.9999H8.79039C6.00039 21.9999 5.91039 20.7799 5.80039 19.2099L5.15039 9.13989" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10.3301 16.5H13.6601" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M9.5 12.5H14.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {window.t("delete")}
              </a>
            </li>
          </ul>
        }
        {conversation.convo_mode == "group" &&
          <ul className="room_actions">
            <li key="group-favorite" id="favoriteLink">
              <a href={Routes.toggle_favorite_conversation_path(conversation.id)} onClick={(e) => this.closeMainMenu(e)} className="d-flex" data-method="put" data-remote="true" data-toggle="tooltip" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={conversation.favorited_by.includes(currentUserId) ? "#ffc107" : "none"}>
                  <path d="M13.7299 3.50989L15.4899 7.02989C15.7299 7.51989 16.3699 7.98989 16.9099 8.07989L20.0999 8.60989C22.1399 8.94989 22.6199 10.4299 21.1499 11.8899L18.6699 14.3699C18.2499 14.7899 18.0199 15.5999 18.1499 16.1799L18.8599 19.2499C19.4199 21.6799 18.1299 22.6199 15.9799 21.3499L12.9899 19.5799C12.4499 19.2599 11.5599 19.2599 11.0099 19.5799L8.01991 21.3499C5.87991 22.6199 4.57991 21.6699 5.13991 19.2499L5.84991 16.1799C5.97991 15.5999 5.74991 14.7899 5.32991 14.3699L2.84991 11.8899C1.38991 10.4299 1.85991 8.94989 3.89991 8.60989L7.08991 8.07989C7.61991 7.98989 8.25991 7.51989 8.49991 7.02989L10.2599 3.50989C11.2199 1.59989 12.7799 1.59989 13.7299 3.50989Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {conversation.favorited_by.includes(currentUserId) ? window.t('chat.conversation.remove_from_favorites') : window.t('chat.conversation.add_to_favorites')}
              </a>
            </li>
            <li key="group-search">
              <a href="" className="d-flex" onClick={this.showSearch}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M22 22L20 20" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Поиск
              </a>
            </li>
            {(conversation.course_id == null || conversation.course_id != null && conversation.admin_id == currentUserId) &&
              <li key="group-clear">
                <a href={Routes.conversation_clear_group_chat_path(conversation.id)} onClick={this.clearChat} className="d-flex" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18.8504 9.13989L18.2004 19.2099C18.0904 20.7799 18.0004 21.9999 15.2104 21.9999H8.79039C6.00039 21.9999 5.91039 20.7799 5.80039 19.2099L5.15039 9.13989" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.3301 16.5H13.6601" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.5 12.5H14.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  {window.t("clear")}
                </a>
              </li>
            }
          </ul>
        }

        <div className="chat_main">

          <ConversationMessages
            subscription={this.subscription}
            conversation={conversation}
            currentUserId={currentUserId}
            canUpdateConversation={canUpdateConversation}
            search={this.state.search}
            ref="messagesList"
          />
        </div>
        {
          this.state.subscriptionActive &&
          <ConversationMessageForm
            subscription={this.subscription}
            conversation={this.props.conversation}
            ref="messageForm"
          />
        }
      </>
    )
  }

}

export default Conversation
